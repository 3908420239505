<template>
    <v-app>
        <v-container>
            <v-row style="margin-top:20px">
                <v-col cols="12" md="12">

                    <h1 class="headline mb-2  primary--text">{{$t('general_report')}}</h1>
                    <v-card class="pa-5" elevation="5">
                        <template>
                            <div id="app">
                                <GChart type="ColumnChart"
                                        :data="chartData"
                                        :options="chartOptions"
                                        style="height: 350px" />
                            </div>
                        </template>
                    </v-card>
                </v-col>
            </v-row>
            <hr style="margin-top: 60px;" />
            <h1 class="headline mb-2 mt-5 primary--text">{{$t('hotels_profile')}}</h1>
            <h4 class="headline mt-5 text-center">{{$t('list_of_hotels')}}</h4>

            <v-sheet id="slider">
                <v-slide-group v-model="pendingSlider"
                               active-class="success"
                               show-arrows>
                    <v-slide-item v-for="(hotel, index) in hotelData"
                                  :key="index">

                        <v-container>

                            <v-card class="mt-5"
                                    id="slider-card"
                                    max-height="550"
                                    max-width="350"
                                    :elevation="24"
                                    style="background:white">
                                <!--<v-img :src="hotel.logo"
                                       class="white--text align-end"
                                       aspect-ratio="1.7"
                                       style="width: 400px; height: 200px; background-Size: contain ">
                                    <v-card-title>{{hotel.name}}</v-card-title>
                                </v-img>-->

                                <img :src="hotel.logo" style="width: 100%; height: 200px; object-fit: fill; border: 3px solid black;" />

                                <v-card-title style="font-size:28px;">{{hotel.name}}</v-card-title>

                                <v-card-subtitle class="pb-0 black--text" align="left">
                                    <strong>{{hotel.address}}</strong>
                                </v-card-subtitle>
                                <v-card-text class="text--primary">
                                    <div id="scroller"
                                         style="overflow-y: scroll; max-height: 170px">
                                        Soaltee Hotel Limited (SHL), a pioneer in Nepal's
                                        hospitality sector, owns the Soaltee Kathmandu, a 12-acre
                                        five-star deluxe hotel. A landmark in the city of
                                        Kathmandu with a rich heritage, the hotel is the proud
                                        recipient of several prestigious awards and accolades over
                                        the years. Soaltee Hotel Limited is renowned for its rich
                                        history as a pioneer in Nepal�s tourism industry with its
                                        contribution of luxurious properties and legendary
                                        hospitality across the region.
                                    </div>
                                </v-card-text>
                                <v-card-actions class="justify-end">
                                    <!--<button :hotelId="hotel.branchID" @click="$router.push('/hotel/hotelDetails')" class=" white--text cyan darken-2" dark style="padding:5px; border-radius:5px">Details</button>-->
                                    <router-link :to="{name: 'Details', params:{hotelID: hotel.branchID}}">{{$t('table.details')}}</router-link>
                                </v-card-actions>
                            </v-card>
                            <!--<table style="width: 100%">
                                <tr class="">
                                    <td style="width: 50%">
                                        <span class="text--darken-4 orange--text">Hotel Location: </span>
                                    </td>
                                    <td style="width: 50%; text-align: right">
                                        <span class="text--darken-4 orange--text">
                                            {{hotel.address}}
                                        </span>
                                    </td>
                                </tr>
                                <tr>
                                    <td style="width: 50%">
                                        <span class="text--darken-4 green--text">
                                            Hotel Email:
                                        </span>
                                    </td>
                                    <td style="width: 50%; text-align: right">
                                        <span class="text--darken-4 green--text">
                                            {{hotel.email}}
                                        </span>
                                    </td>
                                </tr>
                            </table>-->
                            <!--&lt;!&ndash;<v-row>
                                <v-col cols="auto" class="blue pl-5">
                                    <strong>{{pendingItem.pending}}</strong>
                                </v-col>

                                <v-col
                                        cols="auto"
                                        class="text-center pl-3"
                                > {{pendingItem.moduleName}}
                                </v-col>&ndash;&gt;
                            </v-row>-->
                        </v-container>
                    </v-slide-item>
                </v-slide-group>
            </v-sheet>



















            <!--<template>
                <v-carousel v-model="model">
                    <v-carousel-item v-for="(slide, i) in slides"
                                     :key="i">
                        <v-sheet :color="color"
                                 height="100%"
                                 tile>
                            <v-row class="fill-height"
                                   align="center"
                                   justify="center">
                                <div class="text-h2">
                                    {{slide.hotelName}}
                                </div>
                            </v-row>
                        </v-sheet>
                    </v-carousel-item>
                </v-carousel>
            </template>-->

            <v-container>
                <h4 class="headline mt-10 text-center">{{$t('customer_in_hotels_countrywise')}}</h4>
                <!--<v-expansion-panels hover>
                    <v-expansion-panel>
                        <v-expansion-panel-header> {{$t('table.filter')}} </v-expansion-panel-header>
                        <v-expansion-panel-content>-->
                            <!--<v-menu :close-on-content-click="false"
                                    :nudge-right="40"
                                    class="col-sm-6 col-md-4"
                                    min-width="290px"
                                    offset-y
                                    transition="scale-transition"
                                    v-model="snackbar.menu2">
                                <template v-slot:activator="{ on }">
                                    <v-text-field clearable
                                                  label="From Date"
                                                  prepend-icon="event"
                                                  readonly
                                                  v-model="formData_1.fromDate"
                                                  v-on="on"></v-text-field>
                                </template>
                                <v-date-picker @blur="$v.formData_1.CheckInDate.$touch()"
                                               @input="$v.formData_1.CheckInDate.$touch()"
                                               v-model="formData_1.CheckInDate"></v-date-picker>

                            </v-menu>

                            <v-menu :close-on-content-click="false"
                                    :nudge-right="40"
                                    class="col-sm-6 col-md-4"
                                    min-width="290px"
                                    offset-y
                                    transition="scale-transition"
                                    v-model="snackbar.menu3">
                                <template v-slot:activator="{ on }">
                                    <v-text-field clearable
                                                  label="Check Out Date"
                                                  prepend-icon="event"
                                                  readonly
                                                  v-model="formData_1.toDate"
                                                  v-on="on"></v-text-field>
                                </template>
                                <v-date-picker  @blur="$v.formData_1.CheckOutDate.$touch()"
                                               @input="$v.formData_1.CheckOutDate.$touch()"
                                               v-model="formData_1.CheckOutDate"></v-date-picker>
                            </v-menu>-->
                        <!--</v-expansion-panel-content>
                    </v-expansion-panel>
                </v-expansion-panels>-->
                <v-row>
                    <v-col cols="12" md="12" class="mt-5">
                        <v-card elevation="5">
                            <template>
                                <div id="app">
                                    <GChart type="ColumnChart"
                                            :data="barsData"
                                            :options="chartOptions"
                                            style="height: 400px;" />
                                </div>
                            </template>
                        </v-card>
                    </v-col>
                </v-row>
            </v-container>


            <v-container>
                <h4 class="headline mt-10 text-center">{{$t('total_occupied_rooms_in_hotels')}}</h4>
                <!--<v-expansion-panels hover>
                    <v-expansion-panel>
                        <v-expansion-panel-header> {{$t('table.filter')}} </v-expansion-panel-header>
                        <v-expansion-panel-content>-->
                            <!--<v-menu :close-on-content-click="false"
                                    :nudge-right="40"
                                    class="col-sm-6 col-md-4"
                                    min-width="290px"
                                    offset-y
                                    transition="scale-transition"
                                    v-model="snackbar.menu2">
                                <template v-slot:activator="{ on }">
                                    <v-text-field clearable
                                                  label="From Date"
                                                  prepend-icon="event"
                                                  readonly
                                                  v-model="formData_1.fromDate"
                                                  v-on="on"></v-text-field>
                                </template>
                                <v-date-picker @blur="$v.formData_1.CheckInDate.$touch()"
                                               @input="$v.formData_1.CheckInDate.$touch()"
                                               v-model="formData_1.CheckInDate"></v-date-picker>

                            </v-menu>

                            <v-menu :close-on-content-click="false"
                                    :nudge-right="40"
                                    class="col-sm-6 col-md-4"
                                    min-width="290px"
                                    offset-y
                                    transition="scale-transition"
                                    v-model="snackbar.menu3">
                                <template v-slot:activator="{ on }">
                                    <v-text-field clearable
                                                  label="Check Out Date"
                                                  prepend-icon="event"
                                                  readonly
                                                  v-model="formData_1.toDate"
                                                  v-on="on"></v-text-field>
                                </template>
                                <v-date-picker  @blur="$v.formData_1.CheckOutDate.$touch()"
                                               @input="$v.formData_1.CheckOutDate.$touch()"
                                               v-model="formData_1.CheckOutDate"></v-date-picker>
                            </v-menu>-->
                        <!--</v-expansion-panel-content>
                    </v-expansion-panel>
                </v-expansion-panels>-->
                <v-row>
                    <v-col cols="12" md="12" class="mt-5">
                        <v-card elevation="5">
                            <template>
                                <div id="app">
                                    <GChart type="ColumnChart"
                                            :data="barsDat"
                                            :options="chartOptions"
                                            style="height: 400px;" />
                                </div>
                            </template>
                        </v-card>
                    </v-col>
                </v-row>
            </v-container>


            <v-container>
                <h4 class="headline mt-10 text-center">{{$t('customer_visit_in_hotels')}}</h4>
                <v-expansion-panels hover>
                    <v-expansion-panel>
                        <v-expansion-panel-header> {{$t('table.filter')}} </v-expansion-panel-header>
                        <v-expansion-panel-content>
                            <v-row class="mt-5">
                                <v-col cols="12" md="2"></v-col>
                                <v-col cols="12" md="4">
                                    <v-menu :close-on-content-click="false"
                                            :nudge-right="40"
                                            class="col-sm-5 col-md-4"
                                            min-width="290px"
                                            offset-y
                                            transition="scale-transition"
                                            v-model="snackbar.menu2">
                                        <template v-slot:activator="{ on }">
                                            <v-text-field clearable
                                                          :label="$t('from_date')"
                                                          prepend-inner-icon="event"
                                                          readonly
                                                          dense
                                                          outlined
                                                          v-model="form.fromDate"
                                                          v-on="on"></v-text-field>
                                        </template>
                                        <v-date-picker @blur="$v.form.fromDate.$touch()"
                                                       @input="$v.form.fromDate.$touch()"
                                                       v-model="form.fromDate"></v-date-picker>

                                    </v-menu>
                                </v-col>
                                <v-col cols="12" md="1"></v-col>
                                <v-col cols="12" md="4">
                                    <v-menu :close-on-content-click="false"
                                            :nudge-right="40"
                                            class="col-sm-5 col-md-4"
                                            min-width="290px"
                                            offset-y
                                            transition="scale-transition"
                                            v-model="snackbar.menu3">
                                        <template v-slot:activator="{ on }">
                                            <v-text-field clearable
                                                          :label="$t('to_date')"
                                                          prepend-inner-icon="event"
                                                          readonly
                                                          dense
                                                          outlined
                                                          v-model="form.toDate"
                                                          v-on="on"></v-text-field>
                                        </template>
                                        <v-date-picker @blur="$v.form.toDate.$touch()"
                                                       @input="$v.form.toDate.$touch()"
                                                       v-model="form.toDate"></v-date-picker>
                                    </v-menu>
                                </v-col>

                                <v-col cols="12" md="1"></v-col>

                            </v-row>
                            
                            <v-row align="center" justify="center">
                                <v-btn color="primary" dark @click="searchCustomerCount" class="mr-1">
                                    <i class="material-icons">search</i>{{$t('search')}}
                                </v-btn>
                                <v-btn color="error" dark @click="clearInput">
                                    <i class="material-icons">clear</i>{{$t('clear')}}
                                </v-btn>
                            </v-row>
                        </v-expansion-panel-content>
                    </v-expansion-panel>
                </v-expansion-panels>
                <v-row>
                    <v-col cols="12" md="12" class="mt-5">
                        <v-card elevation="5">
                            <template>
                                <div id="app">
                                    <GChart type="ColumnChart"
                                            :data="barsDatas"
                                            :options="chartOptions"
                                            style="height: 400px;" />
                                </div>
                            </template>
                        </v-card>
                    </v-col>
                </v-row>
            </v-container>


            <!--#region test-->


            <hr style="margin-top: 60px;" />
            <h1 class="headline mb-2 mt-5 primary--text">{{$t('hotels_details')}}</h1>
            <v-container>
                <v-row>
                    <v-col cols="12" md="4">
                        <v-expansion-panels hover>
                            <v-expansion-panel>
                                <v-expansion-panel-header> {{$t('table.filter')}} </v-expansion-panel-header>
                                <v-expansion-panel-content>
                                    <v-row align="center">
                                        <v-col class="float-right" cols="12" sm="4">
                                            <v-autocomplete v-model="serverParams.ProvinceID"
                                                            :items="ProvinceList"
                                                            clearable
                                                            dense
                                                            outlined
                                                            @change="getDistrictList"
                                                            item-text="value"
                                                            item-value="id"
                                                            :label="$t('select_province')">
                                            </v-autocomplete>
                                        </v-col>
                                        <v-col class="float-right" cols="12" sm="4">
                                            <v-autocomplete v-model="serverParams.DistrictName"
                                                            clearable
                                                            :items="DistrictList"
                                                            item-text="value"
                                                            dense
                                                            outlined
                                                            item-value="id"
                                                            :label="$t('select_district')">
                                            </v-autocomplete>
                                        </v-col>
                                        <v-col class="float-right" cols="12" sm="4">
                                            <v-text-field clearable
                                                          v-model="serverParams.BranchName"
                                                          dense
                                                            outlined
                                                          :label="$t('hotel_name')">

                                            </v-text-field>

                                        </v-col>
                                    </v-row>
                                    <v-row align="center" justify="center">
                                        <v-btn color="primary" dark @click="searchInput" class="mr-1">
                                            <i class="material-icons">search</i>{{$t('search')}}
                                        </v-btn>
                                        <v-btn color="error" dark @click="clearInput">
                                            <i class="material-icons">clear</i>{{$t('clear')}}
                                        </v-btn>
                                    </v-row>
                                </v-expansion-panel-content>
                            </v-expansion-panel>
                        </v-expansion-panels>
                        <v-card>
                            <v-list style="background-color: #077dfa">
                                <v-list-item>
                                    <v-list-item-avatar>
                                        <v-title id="table" class="white--text"><strong>{{$t('s_no')}}</strong></v-title>
                                    </v-list-item-avatar>

                                    <v-list-item-content>
                                        <v-list-item-title id="table" class="white--text"> <strong>{{$t('hotel_name')}}</strong> </v-list-item-title>
                                    </v-list-item-content>
                                    <v-list-item-content align="right">
                                        <v-list-item-title id="table" class="white--text"><strong>{{$t('number_of_customers')}}</strong></v-list-item-title>
                                    </v-list-item-content>
                                </v-list-item>
                            </v-list>
                        </v-card>
                        <v-card style="overflow-y: scroll; height: 350px">
                            <v-list v-for="(hotelCustomer, i) in hotelCustomerData"
                                    :key="i">
                                <!--hotelCustomer.backgroundColor-->

                                <v-list-item :style="{background: hotelCustomer.backgroundColor }">
                                    <v-list-item-avatar style="background-color: white; border-radius: 10px;">
                                        <v-title id="table" class="black--text">{{i+1}}</v-title>
                                    </v-list-item-avatar>
                                    <v-list-item-content>
                                        <v-list-item-title id="table" class="white--text">{{hotelCustomer.branchName}}</v-list-item-title>
                                        <v-list-item-subtitle id="table" class="white--text">
                                            {{hotelCustomer.branchAddress}}
                                        </v-list-item-subtitle>
                                    </v-list-item-content>
                                    <v-list-item-avatar align="right">
                                        <v-list-item-title id="table" class="white--text">{{hotelCustomer.numberOfPerson}}</v-list-item-title>

                                    </v-list-item-avatar>
                                </v-list-item>
                                <v-divider inset></v-divider>
                            </v-list>
                        </v-card>
                    </v-col>
                    <v-col cols="12" md="4">
                        <v-expansion-panels hover>
                            <v-expansion-panel>
                                <v-expansion-panel-header> {{$t('table.filter')}} </v-expansion-panel-header>
                                <v-expansion-panel-content>
                                    <v-row align="center">
                                        <v-col class="float-right" cols="12" sm="4">
                                            <v-autocomplete v-model="serverParamss.ProvinceID"
                                                            :items="ProvinceList"
                                                            clearable
                                                            dense
                                                            outlined
                                                            @change="getDistrictList"
                                                            item-text="value"
                                                            item-value="id"
                                                            :label="$t('select_province')">
                                            </v-autocomplete>
                                        </v-col>
                                        <v-col class="float-right" cols="12" sm="4">
                                            <v-autocomplete v-model="serverParamss.DistrictName"
                                                            clearable
                                                            dense
                                                            outlined
                                                            :items="DistrictList"
                                                            item-text="value"
                                                            item-value="id"
                                                            :label="$t('select_district')">
                                            </v-autocomplete>
                                        </v-col>
                                        <v-col class="float-right" cols="12" sm="4">
                                            <v-text-field clearable
                                                          dense
                                                            outlined
                                                          v-model="serverParamss.BranchName"
                                                          :label="$t('hotel_name')">

                                            </v-text-field>

                                        </v-col>
                                    </v-row>
                                    <v-row align="center" justify="center">
                                        <v-btn color="primary" dark @click="searchInputEmployee" class="mr-1">
                                            <i class="material-icons">search</i>{{$t('search')}}
                                        </v-btn>
                                        <v-btn color="error" dark @click="clearInputEmployee">
                                            <i class="material-icons">clear</i>{{$t('clear')}}
                                        </v-btn>
                                    </v-row>
                                </v-expansion-panel-content>
                            </v-expansion-panel>
                        </v-expansion-panels>
                        <v-card>
                            <v-list style="background-color: #077dfa">
                                <v-list-item>
                                    <v-list-item-avatar>
                                        <v-title id="table" class="white--text"><strong>{{$t('s_no')}}</strong></v-title>
                                    </v-list-item-avatar>
                                    <v-list-item-content>
                                        <v-list-item-title id="table" class="white--text"><strong> {{$t('hotel_name')}} </strong></v-list-item-title>
                                    </v-list-item-content>
                                    <v-list-item-content align="right">
                                        <v-list-item-title id="table" class="white--text"><strong> {{$t('number_of_employees')}}</strong></v-list-item-title>
                                    </v-list-item-content>
                                </v-list-item>
                            </v-list>
                        </v-card>
                        <v-card style="overflow-y: scroll; height: 350px">
                            <v-list v-for="(hotelEmployee, j) in hotelEmployeeData"
                                    :key="j">
                                <v-list-item :style="{background: hotelEmployee.backgroundColor }">
                                    <v-list-item-avatar style="background-color: white; border-radius: 10px;">

                                        <v-title id="table" class="black--text">{{j+1}}</v-title>
                                    </v-list-item-avatar>
                                    <v-list-item-content>
                                        <v-list-item-title id="table" class="white--text"> {{hotelEmployee.branchName}} </v-list-item-title>
                                        <v-list-item-subtitle id="table" class="white--text">
                                            {{hotelEmployee.branchAddress}}
                                        </v-list-item-subtitle>
                                    </v-list-item-content>
                                    <v-list-item-avatar align="right">
                                        <v-list-item-title id="table" class="white--text">{{hotelEmployee.totalEmployee}}</v-list-item-title>
                                    </v-list-item-avatar>
                                </v-list-item>
                                <!--<v-list-item style="background-color:green">
                                    <v-list-item-avatar style="background-color: #077dfa; border-radius: 10px">
                                        <v-title class="white--text">10</v-title>
                                    </v-list-item-avatar>
                                    <v-list-item-content>
                                        <v-list-item-title> Hotel Hetauda </v-list-item-title>
                                        <v-list-item-subtitle>
                                            Hetauda
                                        </v-list-item-subtitle>
                                    </v-list-item-content>
                                    <v-list-item-content align="right">
                                        <v-list-item-title class="primary--text">100</v-list-item-title>
                                    </v-list-item-content>
                                </v-list-item>-->
                                <v-divider inset></v-divider>
                            </v-list>
                        </v-card>
                    </v-col>
                    <v-col cols="12" md="4" elevation="10">
                        <v-expansion-panels hover>
                            <v-expansion-panel>
                                <v-expansion-panel-header> {{$t('table.filter')}} </v-expansion-panel-header>
                                <v-expansion-panel-content>
                                    <v-row align="center">
                                        <v-col class="float-right" cols="12" sm="4">
                                            <v-autocomplete v-model="serverParamsss.ProvinceID"
                                                            :items="ProvinceList"
                                                            clearable
                                                            dense
                                                            outlined
                                                            @change="getDistrictList"
                                                            item-text="value"
                                                            item-value="id"
                                                            :label="$t('select_province')">
                                            </v-autocomplete>
                                        </v-col>
                                        <v-col class="float-right" cols="12" sm="4">
                                            <v-autocomplete v-model="serverParamsss.DistrictName"
                                                            clearable
                                                            dense
                                                            outlined
                                                            :items="DistrictList"
                                                            item-text="value"
                                                            item-value="id"
                                                            :label="$t('select_district')">
                                            </v-autocomplete>
                                        </v-col>
                                        <v-col class="float-right" cols="12" sm="4">
                                            <v-text-field clearable
                                                          dense
                                                            outlined
                                                          v-model="serverParamsss.BranchName"
                                                          :label="$t('hotel_name')">

                                            </v-text-field>

                                        </v-col>
                                    </v-row>
                                    <v-row align="center" justify="center">
                                        <v-btn color="primary" dark @click="searchInputHouses" class="mr-1">
                                            <i class="material-icons">search</i>{{$t('search')}}
                                        </v-btn>
                                        <v-btn color="error" dark @click="clearInputHouses">
                                            <i class="material-icons">clear</i>{{$t('clear')}}
                                        </v-btn>
                                    </v-row>
                                </v-expansion-panel-content>
                            </v-expansion-panel>
                        </v-expansion-panels>
                        <v-card>
                            <v-list style="background-color: #077dfa">
                                <v-list-item>
                                    <v-list-item-avatar>
                                        <v-title id="table" class="white--text"><strong>{{$t('s_no')}}</strong></v-title>
                                    </v-list-item-avatar>
                                    <v-list-item-content>
                                        <v-list-item-title id="table" class="white--text"><strong> {{$t('hotel_name')}} </strong></v-list-item-title>
                                    </v-list-item-content>
                                    <v-list-item-content align="right">
                                        <v-list-item-title id="table" class="white--text"><strong> {{$t('number_of_rooms')}}</strong></v-list-item-title>
                                    </v-list-item-content>
                                </v-list-item>
                            </v-list>
                        </v-card>
                        <v-card style="overflow-y: scroll; height: 350px">
                            <v-list v-for="(hotelRoom, j) in hotelRoomData"
                                    :key="j">
                                <v-list-item :style="{background: hotelRoom.backgroundColor }">
                                    <v-list-item-avatar style="background-color: white; border-radius: 10px;">
                                        <v-title id="table">{{j+1}}</v-title>
                                    </v-list-item-avatar>
                                    <v-list-item-content>
                                        <v-list-item-title id="table" class="white--text"> {{hotelRoom.branchName}} </v-list-item-title>
                                        <v-list-item-subtitle id="table" class="white--text">
                                            {{hotelRoom.branchAddress}}
                                        </v-list-item-subtitle>
                                    </v-list-item-content>
                                    <v-list-item-avatar align="right">
                                        <v-list-item-title id="table" class="white--text">{{hotelRoom.totalRoom}}</v-list-item-title>
                                    </v-list-item-avatar>
                                </v-list-item>
                                <v-divider></v-divider>
                            </v-list>
                        </v-card>
                    </v-col>

                </v-row>
            </v-container>


            <!--#endRegion-->



        </v-container>
    </v-app>
</template>

<script>
    import { mdiClock, mdiStore, mdiNaturePeople, mdiHeadset } from "@mdi/js";
    //import publicAxios from '../../simple-axios'
    import publicAxios from "axios";
    import axios from "axios";
    /*import router from "../../../routes/router";*/
    export default {
        data: () => ({
            pendingSlider: null,
            IndividulData: [],
            ProvinceList: [],
            DistrictList: [],
            serverParams: {
                search: "",
                columnFilters: {},
                sort: [
                    {
                        field: "isActive",
                        type: "desc"
                    }
                ],
                page: 1,
                perPage: 50
            },
            serverParamss: {
                search: "",
                columnFilters: {},
                sort: [
                    {
                        field: "isActive",
                        type: "desc"
                    }
                ],
                page: 1,
                perPage: 50
            },
            serverParamsss: {
                search: "",
                columnFilters: {},
                sort: [
                    {
                        field: "isActive",
                        type: "desc"
                    }
                ],
                page: 1,
                perPage: 50
            },
            
            snackbar: {
                menu2: false,
                menu3: false
            },
           
            form: {
                fromDate: '',
                toDate: ''
            },
            hotelData: [],
            hotelCustomerData: [],
            hotelEmployeeData: [],
            hotelRoomData: [],
            chartData: [],
            chartOptions: {
                chart: {
                    title: "Company Performance",
                    subtitle: "Sales, Expenses, and Profit: 2014-2020",
                },
            },

            barsData: [],
            barsDatas: [],
            barsDat: [],
            RoomData: [
                ["Year", " Total Room", "Room Booked"],
                ["2011", 2000, 1000],
                ["2012", 2170, 1500],
                ["2013", 2060, 1800],
                ["2014", 3030, 2100],
                ["2015", 4025, 3200],
                ["2016", 4040, 3400],
                ["2017", 5000, 4000],
                ["2018", 5800, 4100],
                ["2019", 6800, 3000],
                ["2020", 7000, 600],
            ],
            slides: [
                {
                    img: '../../', hotelName: 'The Soaltee Kathmandu', location: 'Tahachal Marg, Kathmandu 44600, Nepal', description: "Soaltee Hotel Limited (SHL), a pioneer in Nepal's hospitality sector, owns the Soaltee Kathmandu, a 12-acre five- star deluxe hotel.A landmark in the city of Kathmandu with a rich heritage, the hotel is the proud recipient of several prestigious awards and accolades over the years.Soaltee Hotel Limited is renowned for its rich  history as a pioneer in Nepal�s tourism industry with its  contribution of luxurious properties and legendary hospitality across the region."
                },
                {
                    img: '../../', hotelName: 'The Yak & Yeti', location: 'Tahachal Marg, Kathmandu 44600, Nepal', description: "Soaltee Hotel Limited (SHL), a pioneer in Nepal's hospitality sector, owns the Soaltee Kathmandu, a 12-acre five- star deluxe hotel.A landmark in the city of Kathmandu with a rich heritage, the hotel is the proud recipient of several prestigious awards and accolades over the years.Soaltee Hotel Limited is renowned for its rich  history as a pioneer in Nepal�s tourism industry with its  contribution of luxurious properties and legendary hospitality across the region.",
                },
                {
                    img: '../../', hotelName: 'The Soaltee Kathmandu', location: 'Tahachal Marg, Kathmandu 44600, Nepal', description: "Soaltee Hotel Limited (SHL), a pioneer in Nepal's hospitality sector, owns the Soaltee Kathmandu, a 12-acre five- star deluxe hotel.A landmark in the city of Kathmandu with a rich heritage, the hotel is the proud recipient of several prestigious awards and accolades over the years.Soaltee Hotel Limited is renowned for its rich  history as a pioneer in Nepal�s tourism industry with its  contribution of luxurious properties and legendary hospitality across the region.",
                }








            ],
            series: [400, 450, 350, 310, 280, 330, 520, 120, 130, 250],
            series1: [200, 250, 210, 190, 230, 240, 120, 220, 160, 150],
            chartsOptions: {
                chart: {
                    width: 800,
                    type: "donut",
                },
                labels: [
                    "Hyatt Regency",
                    "Yak & Yeti",
                    "Godavari Village Resort",
                    "Hotel Everest View",
                    "Begnas Lake Resort",
                    "Hotel Golden Plaza",
                    "The Dwarika's Hotel",
                    "Kathmandu Marriott Hotel",
                    "Kathmandu Marriott Hotel",
                    "Pokhara Grande",
                ],
                responsive: [
                    {
                        breakpoint: 480,
                        options: {
                            chart: {
                                width: 500,
                            },
                            legend: {
                                position: "bottom",
                            },
                        },
                    },
                ],
            },
            width: 2,
            radius: 10,
            padding: 8,
            lineCap: "round",
            value: [0, 2, 5, 9, 5, 10, 3, 5, 0, 0, 1, 8, 2, 9, 0],
            fill: false,
            type: "trend",
            autoLineWidth: false,
            svgClock: mdiClock,
            svgStore: mdiStore,
            svgCustomer: mdiNaturePeople,
            svgHeading: mdiHeadset,
        }),
        methods: {
            async getHotelData() {
                const { data } = await publicAxios.post('HotelDetails/GetAllHotelDetailsList')
                this.hotelData = data
                console.log('this.hotelData', this.hotelData)
            },
            async getCustomerData() {
                const { data } = await publicAxios.get('HotelDetails/GetHotelCustomerCountsAsync')
                this.hotelCustomerData = data
                console.log('this.hotelCustomerData', this.hotelCustomerData)
            },
            async getEmployeeData() {

                const { data } = await publicAxios.get('HotelDetails/GetHotelEmployeesCountsAsync')
                this.hotelEmployeeData = data

                console.log('this.hotelEmployeeData', this.hotelEmployeeData)
            },
            async getRoomData() {
                const { data } = await publicAxios.get('HotelDetails/GetHotelRoomcountAsync')
                this.hotelRoomData = data
                console.log('this.hotelRoomData', this.hotelRoomData)
            },
            async getHotelChartData() {
                const { data } = await publicAxios.get('HotelDetails/GetHotelLineChartAsync?Type=Hotel')
                let formatedData = this.getChartData(data)
                this.chartData = formatedData
            },

            async getHotelCustomerCountryWise() {
                const { data } = await publicAxios.get('HotelDetails/GetHotelCustomerCountrywisecountAsync?Type=Hotel')

                let formatedData = this.getChartData(data)
                this.barsData = formatedData
                console.log('this.hotelCustomerCountryWise', this.barsData)
            },
            async getHotelCustomerCount() {

                const { data } = await axios.post('HotelDetails/GetHotelCustomerCountAsync', this.form)

                let formatedData = this.getChartData(data)
                this.barsDatas = formatedData
                console.log('this.hotelCustomerCount', this.barsDatas)
            },
            async GetHotelRoomOccupied() {
                const { data } = await axios.post('HotelDetails/GetHotelRoomOccupiedAsync')

                let formatedData = this.getChartData(data)
                this.barsDat = formatedData
                console.log('this.hotelRoomOccupied', this.barsDat)
            },

            getChartData(data) {
                console.log(data)
                let arr = []
                arr[0] = Object.keys(data[0])
                for (let i = 1; i < data.length + 1; i++) {
                    arr[i] = Object.values(data[i - 1])
                }
                return arr;
            },
            updateParams(newProps) {
                this.serverParams = Object.assign({}, this.serverParams, newProps);
            },
            updateParamss(newProps) {
                this.serverParamss = Object.assign({}, this.serverParamss, newProps);
            },
            updateParamsss(newProps) {
                this.serverParamsss = Object.assign({}, this.serverParamsss, newProps);
            },
            loadItems() {
                this.isLoading = true
                let param = {

                    ProvinceID: this.serverParams.ProvinceID != null && this.serverParams.ProvinceID != undefined ? parseInt(this.serverParams.ProvinceID) : 0,
                    District: this.serverParams.DistrictName,
                    BranchName: this.serverParams.BranchName
                };
                axios.post("HotelDetails/GetHotelCustomerCountsAsyncs", param).then((response) => {
                    console.log('response', response)
                    this.hotelCustomerData = response.data;
                    this.isLoading = false
                }).catch((e) => {
                    console.log(e)
                    this.isLoading = false
                })
            },
            loadItemsEmployee() {
                this.isLoading = true
                let param = {

                    ProvinceID: this.serverParamss.ProvinceID != null && this.serverParamss.ProvinceID != undefined ? parseInt(this.serverParamss.ProvinceID) : 0,
                    District: this.serverParamss.DistrictName,
                    BranchName: this.serverParamss.BranchName
                };
                axios.post("HotelDetails/GetHotelEmployeesCountsAsyncs", param).then((response) => {
                    console.log('response', response)
                    this.hotelEmployeeData = response.data;
                    this.isLoading = false
                }).catch((e) => {
                    console.log(e)
                    this.isLoading = false
                })
            },
            loadItemsHouses() {
                this.isLoading = true
                let param = {

                    ProvinceID: this.serverParamsss.ProvinceID != null && this.serverParamsss.ProvinceID != undefined ? parseInt(this.serverParamsss.ProvinceID) : 0,
                    District: this.serverParamsss.DistrictName,
                    BranchName: this.serverParamsss.BranchName
                };
                axios.post("HotelDetails/GetHotelRoomcountAsyncs", param).then((response) => {
                    console.log('response', response)
                    this.hotelRoomData = response.data;
                    this.isLoading = false
                }).catch((e) => {
                    console.log(e)
                    this.isLoading = false
                })
            },
            searchInput() {
                this.loadItems();
            },
            searchInputEmployee() {
                this.loadItemsEmployee();
            },
            searchInputHouses() {
                this.loadItemsHouses();
            },
            searchCustomerCount() {
                this.getHotelCustomerCount();
            },
            async getProvinceList() {
                const ProvinceList = await axios.get('Province/DDLProvinceList')
                this.ProvinceList = ProvinceList.data
                console.log('province list: ', this.ProvinceList)
            },
            async getDistrictList(val) {
                let { data } = await axios.get('District/GetDistrictListByProvince/' + val)
                console.log("district: ", this.data)
                this.DistrictList = data

            },
            clearInputEmployee() {
                this.serverParamss.ProvinceID = null
                this.serverParamss.DistrictName = null
                this.serverParamss.BranchName = null
                this.loadItemsEmployee();
                this.getProvinceList();
            },
            clearInputHouses() {
                this.serverParamsss.ProvinceID = null
                this.serverParamsss.DistrictName = null
                this.serverParamsss.BranchName = null
                this.loadItemsHouses();
                this.getProvinceList();
            },
            clearInput() {
                this.serverParams.ProvinceID = null
                this.serverParams.DistrictName = null
                this.serverParams.BranchName = null
                this.loadItems();
                this.getProvinceList();
            }

        },
        components: {

        },
        computed: {
            theme() {
                return (this.$vuetify.theme.dark) ? 'dark' : 'light'
            }
        },
        created() {
            this.getHotelData()
            this.getCustomerData()
            this.getEmployeeData()
            this.getHotelChartData()
            this.getRoomData()
            this.getHotelCustomerCountryWise()
            this.getProvinceList()
            this.getHotelCustomerCount()
            this.GetHotelRoomOccupied()
        }

    }
</script>
<style scoped>
    ::-webkit-scrollbar {
        width: 3px;
    }

    /* Track */
    ::-webkit-scrollbar-track {
        background: #f1f1f1;
    }

    /* Handle */
    ::-webkit-scrollbar-thumb {
        background: #888;
    }

        /* Handle on hover */
        ::-webkit-scrollbar-thumb:hover {
            background: #555;
        }

    .v-progress-circular {
        margin: 1rem;
    }

    @media screen and (max-width:1903px) {
        #slider-card {
            width: 328px;
        }

        #table {
            font-size: 12px !important;
        }
    }

    @media screen and (max-width:1263px) {
        #slider-card {
            width: 362px;
        }
    }
</style>