<template>
    <div>
        <div v-if="isDivision">
            <DivisionDashboard></DivisionDashboard>
        </div>
        <div v-else-if="isProvinceModule">
            <DivisionDashboard></DivisionDashboard>
        </div>
        <div v-else-if="isAssociation">
            <AssociationDivisionDashboard></AssociationDivisionDashboard>
        </div>
        <div v-else-if="isParentDivision">
            <ParentDivisionDashboard></ParentDivisionDashboard>
        </div>
        <div v-else>
            <template>
                <v-container>
                    <h4 class="headline mt-5 text-center primary--text" v-if="!isNepaliLanguage"><strong>{{hotelBranchId.name}}</strong></h4>
                    <h4 class="headline mt-5 text-center primary--text" v-else><strong>{{hotelBranchId.nameNepali}}</strong></h4>
                    <div id="MainContainer">
                        <v-row>
                            <v-col cols="12" sm="10">
                                <!--<v-card elevation="5">
                            <template>
                                <v-sheet color="white" height="700" align="center">
                                    <v-row class="fill-height" align="center" justify="center">
                                        <div class="grey--text">-->
                                <!--<v-img v-bind:src="hotelBranchId.logo"
                                   class="white--text align-end img-fit"
                                   style="width:400px; height:550px">

                            </v-img>-->
                                <!--<img :src="hotelBranchId.logo" style="width: 100%; height: 100%; object-fit: fill; border: 3px solid black;" />
                                            </div>
                                        </v-row>
                                    </v-sheet>
                                </template>
                            </v-card>-->
                                <v-card class="mt-5"
                                        id="slider-card"
                                        max-height="550"
                                        width="100%"
                                        :elevation="24"
                                        style="background:white">

                                    <img :src="hotelBranchId.logo" id="hotellogo" />
                                </v-card>
                            </v-col>
                            <v-col cols="12" sm="2">
                                <v-row class="mt-10">
                                    <v-card id="box">

                                        <v-col cols="12" sm="12" class="mt-2">

                                            <v-card id="sideMainBox" class="box" @click="$router.push('/branch')" color="red" elevation="5">
                                                <p id="titleForBox">Profile</p>
                                                <v-row>
                                                    <v-col cols="12" sm="4">
                                                        <v-list-item three-line>
                                                            <v-list-avatar>
                                                                <v-icon class="boxIcon" color="white">people</v-icon>
                                                            </v-list-avatar>
                                                        </v-list-item>
                                                    </v-col>
                                                    <v-col cols="12" sm="8">
                                                        <v-list-item three-line style="padding:0 5px;">
                                                            <v-list-item-content class="text-left">
                                                                <div>
                                                                    <p id="sideBox">
                                                                        {{$t('profile')}}
                                                                    </p>
                                                                </div>
                                                            </v-list-item-content>
                                                        </v-list-item>
                                                    </v-col>
                                                </v-row>
                                            </v-card>
                                        </v-col>

                                        <v-col cols="12" sm="12" class="mt-2">
                                            <v-card id="sideMainBox" class="box" @click="$router.push('/hotel')" color="#006400" elevation="5">
                                                <p id="titleForBox">Customer</p>
                                                <v-row>
                                                    <v-col cols="12" sm="4">
                                                        <v-list-item three-line>

                                                            <v-list-item-avatar>
                                                                <v-icon class="boxIcon" color="white">people</v-icon>
                                                            </v-list-item-avatar>

                                                        </v-list-item>
                                                    </v-col>
                                                    <v-col cols="12" sm="8">
                                                        <v-list-item three-line style="padding:0 5px;">
                                                            <v-list-item-content class="text-left">
                                                                <div>
                                                                    <p id="sideBox">
                                                                        {{$t('customer')}}
                                                                    </p>
                                                                </div>
                                                            </v-list-item-content>
                                                        </v-list-item>
                                                    </v-col>
                                                </v-row>
                                            </v-card>
                                        </v-col>

                                        <v-col cols="12" sm="12" class="mt-2">
                                            <v-card id="sideMainBox" @click="$router.push('/hotelEmployee')" color="#FFA500" elevation="5">
                                                <p id="titleForBox">Employee</p>
                                                <v-row>
                                                    <v-col cols="12" sm="4">
                                                        <v-list-item three-line>
                                                            <v-list-item-avatar>
                                                                <v-icon class="boxIcon" color="white">directions_run</v-icon>
                                                            </v-list-item-avatar>
                                                        </v-list-item>
                                                    </v-col>
                                                    <v-col cols="12" sm="8">
                                                        <v-list-item three-line style="padding:0 5px;">
                                                            <v-list-item-content class="text-left">
                                                                <div>
                                                                    <p id="sideBox">
                                                                        {{$t('employee')}}
                                                                    </p>
                                                                    <!--<v-list-item-subtitle class="subtitle-1 mb-14 white--text">
                                                                    Employee
                                                                </v-list-item-subtitle>-->
                                                                </div>
                                                            </v-list-item-content>
                                                        </v-list-item>
                                                    </v-col>
                                                </v-row>
                                            </v-card>
                                        </v-col>

                                        <v-col cols="12" sm="12" class="mt-2">
                                            <v-card id="sideMainBox" @click="$router.push('/availableRoom')" color="#077dfa" elevation="5">
                                                <p id="titleForBox">Room</p>
                                                <v-row>
                                                    <v-col cols="12" sm="4">
                                                        <v-list-item three-line>
                                                            <v-list-item-avatar>
                                                                <v-icon class="boxIcon" color="white">room_service</v-icon>
                                                            </v-list-item-avatar>
                                                        </v-list-item>
                                                    </v-col>
                                                    <v-col cols="12" sm="8">
                                                        <v-list-item three-line style="padding:0 5px;">
                                                            <v-list-item-content class="text-left">
                                                                <div>
                                                                    <p id="sideBox">
                                                                        {{$t('room')}}
                                                                    </p>
                                                                    <!--<v-list-item-subtitle class="subtitle-1 mb-14 white--text">
                                                                    Room
                                                                </v-list-item-subtitle>-->
                                                                </div>
                                                            </v-list-item-content>
                                                        </v-list-item>
                                                    </v-col>
                                                </v-row>
                                            </v-card>
                                        </v-col>

                                        <v-col cols="12" sm="12" class="mt-2" v-show="!hotelBranchId.headBranchID">
                                            <v-card id="sideMainBox" @click="$router.push('/subBranch')" color="#8058bf" elevation="5">
                                                <p id="titleForBox">SubBranch</p>
                                                <v-row>
                                                    <v-col cols="12" sm="4">
                                                        <v-list-item three-line>
                                                            <v-list-item-avatar>
                                                                <v-icon class="boxIcon" color="white">room_service</v-icon>
                                                            </v-list-item-avatar>
                                                        </v-list-item>
                                                    </v-col>
                                                    <v-col cols="12" sm="8">
                                                        <v-list-item three-line style="padding:0 5px;">
                                                            <v-list-item-content class="text-left">
                                                                <div>
                                                                    <p id="sideBox">
                                                                        {{$t('sub_branch')}}
                                                                    </p>
                                                                    <!--<v-list-item-subtitle class="subtitle-1 mb-14 white--text">
                                                                    Room
                                                                </v-list-item-subtitle>-->
                                                                </div>
                                                            </v-list-item-content>
                                                        </v-list-item>
                                                    </v-col>
                                                </v-row>
                                            </v-card>
                                        </v-col>
                                    </v-card>
                                </v-row>
                            </v-col>
                        </v-row>
                    </div>
                    <v-container>
                        <h4 class="headline mt-10 text-center" v-if="!isNepaliLanguage"> {{hotelBranchId.name}} {{$t('customers')}}</h4>
                        <h4 class="headline mt-10 text-center" v-else> {{hotelBranchId.nameNepali}} {{$t('customers')}}</h4>
                        <v-row>
                            <v-col cols="12" md="12" class="mt-5">
                                <v-card elevation="5">
                                    <template>
                                        <div id="app">
                                            <GChart type="ColumnChart"
                                                    :data="barsData"
                                                    :options="chartOptions"
                                                    style="height: 400px; " />
                                        </div>
                                    </template>
                                </v-card>
                            </v-col>
                        </v-row>
                    </v-container>
                    <hr style="margin-top: 60px;" />
                    <!--<v-row>
                    <v-col cols="12" md="6">
                        <h1 class="headline mt-5 primary--text" style="margin-left:20px">{{hotelBranchId.name}} Hotel Customers</h1>
                    </v-col>
                    <v-col cols="12" md="6">
                        <h1 class="headline mt-5 primary--text">{{hotelBranchId.name}} Hotel Employees</h1>
                    </v-col>
                </v-row>-->
                    
                    <v-container>

                        

                        <v-row>

                            <v-col cols="12" md="6">

                                <h1 class="headline mt-5 primary--text" v-if="!isNepaliLanguage">{{hotelBranchId.name}} {{$t('customers')}}</h1>
                                <h1 class="headline mt-5 primary--text" v-else>{{hotelBranchId.nameNepali}} {{$t('customers')}}</h1>
                                <v-card>
                                    <v-list style="background-color: #077dfa">
                                        <v-list-item>
                                            <v-list-item-avatar>
                                                <v-title id="table" class="white--text"><strong>{{$t('s_no')}}</strong></v-title>
                                            </v-list-item-avatar>
                                            <v-list-item-content align="center">
                                                <v-list-item-title id="table" class="white--text"><strong> {{$t('customers')}} {{$t('name')}}</strong> </v-list-item-title>
                                            </v-list-item-content>
                                            <v-list-item-content align="center">
                                                <v-list-item-title id="table" class=" white--text"><strong>{{$t('modules_name.hotel')}} {{$t('room')}}</strong></v-list-item-title>
                                            </v-list-item-content>
                                            <v-list-item-content align="center">
                                                <v-list-item-title id="table" class=" white--text"><strong>{{$t('check_in_date')}}</strong></v-list-item-title>
                                            </v-list-item-content>
                                            <v-list-item-content align="right">
                                                <v-list-item-title id="table" class="white--text"><strong>{{$t('check_out_date')}}</strong></v-list-item-title>
                                            </v-list-item-content>
                                        </v-list-item>
                                    </v-list>
                                </v-card>
                                <v-card style="overflow-y: scroll; height: 350px;">
                                    <v-list v-for="(hotelCheckInOut, i) in hotelCustomerCheckInOut"
                                            :key="i">
                                        <v-list-item :style="{background: hotelCheckInOut.backgroundColor }">
                                            <v-list-item-avatar>
                                                <v-title id="table" class="black--text">{{i+1}}</v-title>
                                            </v-list-item-avatar>
                                            <v-list-item-content align="center">
                                                <v-list-item-title id="table" class="black--text">{{hotelCheckInOut.fullName}} </v-list-item-title>
                                            </v-list-item-content>
                                            <v-list-item-content align="center">
                                                <v-list-item-title id="table" class="mr-18 black--text"> {{hotelCheckInOut.roomNumber}}</v-list-item-title>
                                            </v-list-item-content>
                                            <v-list-item-content align="center">
                                                <v-list-item-title id="table" class=" black--text"> {{hotelCheckInOut.checkInDate}}</v-list-item-title>
                                            </v-list-item-content>
                                            <v-list-item-content align="right">
                                                <v-list-item-title id="table" class="black--text"> {{hotelCheckInOut.checkOutDate}}</v-list-item-title>
                                            </v-list-item-content>
                                        </v-list-item>
                                        <v-divider inset></v-divider>
                                    </v-list>
                                </v-card>
                            </v-col>
                            <v-col cols="12" md="6">
                                <h1 class="headline mt-5 primary--text" v-if="!isNepaliLanguage">{{hotelBranchId.name}} {{$t('employee')}}</h1>
                                <h1 class="headline mt-5 primary--text" v-else>{{hotelBranchId.nameNepali}} {{$t('employee')}}</h1>
                                <v-card>
                                    <v-list style="background-color: #077dfa">
                                        <v-list-item>
                                            <v-list-item-avatar>
                                                <v-title id="table" class="white--text"><strong>{{$t('s_no')}}</strong></v-title>
                                            </v-list-item-avatar>
                                            <v-list-item-content>
                                                <v-list-item-title id="table" align="center" class="white--text"><strong>{{$t('employee_name')}}</strong> </v-list-item-title>
                                            </v-list-item-content>
                                            <v-list-item-content align="center">
                                                <v-list-item-title id="table" class="mr-18 white--text"><strong>{{$t('email')}}</strong></v-list-item-title>
                                            </v-list-item-content>
                                            <v-list-item-content align="center">
                                                <v-list-item-title id="table" class="white--text"><strong> {{$t('position')}}</strong></v-list-item-title>
                                            </v-list-item-content>
                                            <v-list-item-content align="right">
                                                <v-list-item-title id="table" class="white--text"><strong> {{$t('contact_no')}}</strong></v-list-item-title>
                                            </v-list-item-content>
                                        </v-list-item>
                                    </v-list>
                                </v-card>
                                <v-card style="overflow-y: scroll; height: 350px">
                                    <v-card style="height: 350px;">
                                        <v-list v-for="(hotelEmployee, i) in hotelEmployeeDetails"
                                                :key="i">
                                            <v-list-item :style="{background: hotelEmployee.backgroundColor }">
                                                <v-list-item-avatar>
                                                    <v-title id="table" class="black--text">{{i+1}}</v-title>
                                                </v-list-item-avatar>
                                                <v-list-item-content>
                                                    <v-list-item-title id="table" align="center" class="black--text">{{hotelEmployee.empName}}</v-list-item-title>
                                                </v-list-item-content>
                                                <v-list-item-content align="center">
                                                    <v-list-item-title id="table" class="mr-18 black--text">{{hotelEmployee.empEmail}}</v-list-item-title>
                                                </v-list-item-content>
                                                <v-list-item-content align="center">
                                                    <v-list-item-title id="table" class="black--text"> {{hotelEmployee.empType}}</v-list-item-title>
                                                </v-list-item-content>
                                                <v-list-item-content align="right">
                                                    <v-list-item-title id="table" class="black--text">{{hotelEmployee.empPhone}}</v-list-item-title>
                                                </v-list-item-content>
                                            </v-list-item>
                                            <v-divider></v-divider>
                                        </v-list>
                                    </v-card>
                                </v-card>
                                <!--<button @click="$router.push('/hotelEmployee')" class=" white--text primary align-end" dark style="padding:5px; border-radius:5px; margin-top:10px;">For More Details</button>-->
                            </v-col>
                        </v-row>
                    </v-container>


                    <v-container>
                        <h4 class="headline md-10 text-center mt-5" v-if="!isNepaliLanguage">
                            {{hotelBranchId.name}} {{$t('monthly_customers')}}
                        </h4>
                        <h4 class="headline md-10 text-center mt-5" v-else>
                            {{hotelBranchId.nameNepali}} {{$t('monthly_customers')}}
                        </h4>
                        <v-row>

                            <v-col cols="12" md="12" class="mt-5">
                                <template>
                                    <v-card elevation="5">
                                        <div id="app">
                                            <GChart type="ColumnChart"
                                                    :data="customerData"
                                                    :options="chartOptions"
                                                    style="height: 400px;" />
                                        </div>
                                    </v-card>
                                </template>
                            </v-col>


                        </v-row>
                    </v-container>
                    <v-row class="my-5">
                        <hr style="margin-top: 60px; width:100% " />
                        <v-col cols="12" md="12">
                            <h1 class="headline mb-2 primary--text" style="margin-left:10px;" v-if="!isNepaliLanguage">{{hotelBranchId.name}} {{$t('room')}}</h1>
                            <h1 class="headline mb-2 primary--text" style="margin-left:10px;" v-else>{{hotelBranchId.nameNepali}} {{$t('room')}}</h1>
                            <v-card elevation="5">
                                <template>
                                    <div id="app" style="margin-left:10px;">
                                        <GChart type="ColumnChart"
                                                :data="RoomData"
                                                :options="chartOptions"
                                                style="height: 350px" />
                                    </div>
                                </template>
                            </v-card>
                            <!--<button @click="$router.push('/availableRoom')" class=" white--text primary align-end" dark style="padding:5px; border-radius:5px; margin-top:10px;">For More Details</button>-->
                        </v-col>
                    </v-row>
                </v-container>
            </template>
        </div>
    </div>
</template>
<script>
    import DivisionDashboard from '../../dashboard/DivisionDashboard.vue'
    import ParentDivisionDashboard from "../../hotel/Dashboard/HotelAssociationDashboard.vue";
    import AssociationDivisionDashboard from "../../hotel/Dashboard/AssociationDashboard.vue";
    import { mdiClock, mdiStore, mdiNaturePeople, mdiHead } from "@mdi/js";
/*    import VueApexCharts from "vue-apexcharts";*/
    import { mapGetters } from "vuex";
    import publicAxios from '../../../simple-axios'
    // import Vue from 'vue/types/umd';
    // Vue.use(VueApexCharts)
    export default {
        props: [
            "btn-text"
        ],
        computed: {
            ...mapGetters(['getSystemUserData']),
            isDivision() {
                return this.getSystemUserData.role === 'Division'
            },
            isProvinceModule() {
                return this.getSystemUserData.role === 'ProvinceModule'
            },
            isAssociation() {
                return this.getSystemUserData.role === 'Association'
            },
            isParentDivision() {
                return this.getSystemUserData.role === "ParentDivision";
            },
            isBranchData() {
                return this.getSystemUserData.BranchID
            },
            isNepaliLanguage() {
                return this.$i18n.locale === "ne";
            },
        },
        data: () => ({
            hotelCustomerCheckInOut: [],
            hotelEmployeeDetails: [],
            hotelBranchId: [],
            chartData: [],
            chartOptions: {
                chart: {
                    title: "Company Performance",
                    subtitle: "Sales, Expenses, and Profit: 2014-2020",
                },
            },
            barData: [],
            barsData: [],
            customerData: [],
            RoomData: [],
            width: 2,
            radius: 10,
            padding: 8,
            lineCap: "round",
            value: [0, 2, 5, 9, 5, 10, 3, 5, 0, 0, 1, 8, 2, 9, 0],
            fill: false,
            type: "trend",
            autoLineWidth: false,
            svgClock: mdiClock,
            svgStore: mdiStore,
            svgCustomer: mdiNaturePeople,
            svgHeading: mdiHead
        }),
        components: {
            /*apexchart: VueApexCharts,*/
            DivisionDashboard,
            AssociationDivisionDashboard,
            ParentDivisionDashboard,
                /*VueApexCharts,*/
        },
        methods: {
            async getHotelCustomerCountryWise() {
                const { data } = await publicAxios.get('HotelDetails/GetBranchHotelCustomerCountrywisecountAsync/0')
                let formatedData = this.getChartData(data)
                this.barsData = formatedData
                console.log('this.HotelCustomerCountrywisecountAsync', this.barsData)
            },
            async getBranchCustomerCheckInOut() {
                const { data } = await publicAxios.get("HotelDetails/GetBranchHotelCustomerCheckInOut/0")
                this.hotelCustomerCheckInOut = data
                console.log('this.hotelCustomerCheckInOut', this.hotelCustomerCheckInOut)
            },
            async getHotelCustomerCountBar() {
                const { data } = await publicAxios.get("HotelDetails/GetHotelCustomerMonthWise/0" )
                let formatedData = this.getChartData(data)
                this.customerData = formatedData
                console.log('this.HotelBranchCustomerCountBar', this.customerData)
            },
            async getHotelRoomTotalBookedCount() {
                const { data } = await publicAxios.post("HotelDetails/GetHotelRoomStatusMonthWise/0" )
                let formatedData = this.getChartData(data)
                this.RoomData = formatedData
                console.log('this.HotelRoomTotalBookedCount', this.RoomData)
            },
            async getBranchEmployeeDetails() {
                const { data } = await publicAxios.post("HotelDetails/GetHotelEmployeeDetails/0" )
                this.hotelEmployeeDetails = data
                console.log('this.hotelEmployeeDetails', this.hotelEmployeeDetails)
            },
            async getHotelBranchId() {
                const { data } = await publicAxios.post("HotelDetails/GetIndividualHotelDetails/0" )
                this.hotelBranchId = data
                console.log('this.hotelBranchData', this.hotelBranchId)
            },
            getChartData(data) {
                console.log(data)
                let arr = []
                arr[0] = Object.keys(data[0])
                for (let i = 1; i < data.length + 1; i++) {
                    arr[i] = Object.values(data[i - 1])
                }
                return arr;
            }
        },
        created() {
            this.getHotelCustomerCountryWise()
            this.getBranchCustomerCheckInOut()
            this.getBranchEmployeeDetails()
            this.getHotelCustomerCountBar()
            this.getHotelRoomTotalBookedCount()
            this.getHotelBranchId()
        }
       
    };
</script>
<style scoped>
    ::-webkit-scrollbar {
        width: 3px;
    }
    /* Track */
    ::-webkit-scrollbar-track {
        background: #f1f1f1;
    }
    /* Handle */
    ::-webkit-scrollbar-thumb {
        background: #888;
    }
        /* Handle on hover */
        ::-webkit-scrollbar-thumb:hover {
            background: #555;
        }
    .v-progress-circular {
        margin: 1rem;
    }
    @media screen and (min-width:1903px) {
        #sideBox {
           font-size:16px; color:white;
        }
        #titleForBox {
            display: none;
            text-align: center;
        }

        .boxIcon {
            font-size: 36px;
        }
        #hotellogo {
          width: 100%; height: 550px; object-fit: fill;
        }
        #box {
            height:615px;
        }
        #MainContainer {
            margin-left: 90px;
        }
        
    }
    @media screen and (max-width:1903px) {
        #sideBox {
            font-size: 16px;
            color: white;
        }
        #titleForBox {
           display:none;
           text-align:center;
        }
        #table {
            font-size: 12px !important;
        }
        .boxIcon {
            font-size: 26px;
        }
        #hotellogo {
            width: 890px;
            height: 550px;
            object-fit: fill;
        }
        #box {
            height: 615px;
        }
        #MainContainer {
            margin-left: 90px;
        }
    }

    @media screen and (max-width:1263px) {
        #sideBox {
            font-size: 12px;
            color: white;
        }
        .boxIcon {
            font-size: 20px;
        }
        #box {
            height: 400px;
        }
        #table {
            font-size: 10px !important;
        }

        #sideMainBox {
            height: 80px;
        }
        #table {
            font-size: 12px !important;
        }
        #hotellogo {
            width: 650px;
            height: 550px;
            object-fit: fill;
        }
        #MainContainer {
            margin-left: 90px;
        }
    }


    @media screen and (max-width:979px) {
        #sideBox {
            font-size: 10px;
            color: white;
        }

        #sideMainBox {
            height: 70px;
        }

        #box {
            height:485px;
        }
        #table {
            font-size: 10px !important;
        }

        #hotellogo {
            width: 100%;
            height: 550px;
            object-fit: fill;
        }
        #MainContainer {
            margin-left: 70px;
        }
    }
    
    @media screen and (max-width: 860px) {
        #sideBox {
            display: none;
        }
        #table {
            font-size: 10px !important;
        }

        #sideMainBox {
            height: 70px;
            width:100%;
            padding:25px;
        }
        .boxIcon {
          /*  font-size: 26px;
            margin-bottom:15px;*/
          display:none;
        }
        #titleForBox {
            color:white;
            display: block;
            text-align: center;
        }

        #box {
            width: 100%;
            height: 535px;
        }

        #hotellogo {
            width: 100%;
            height: 550px;
            object-fit: fill;
        }
        .col-12 {
            -webkit-box-flex: 0;
            -ms-flex: 0 0 100%;
            flex: 0 0 100%;
            max-width: 100%;
        }
        #MainContainer {
            margin-left: 40px;
        }
  
    }
    @media screen and (max-width: 750px) {
        #sideBox {
            display:none;
        }
        #table {
            font-size: 10px !important;
        }
        #sideMainBox {
            height: 70px;
            width: 100%;
        }


        #box {
            width: 100%;
            height:535px;
        }

        #hotellogo {
            width: 100%;
            height: 450px;
            object-fit: fill;
        }

        .col-12 {
            -webkit-box-flex: 0;
            -ms-flex: 0 0 100%;
            flex: 0 0 100%;
            max-width: 100%;
        }

        #MainContainer {
            margin-left: 40px;
        }
    }
    @media screen and (max-width:640px) {
        #sideBox {
            display: none;
        }
        #table {
            font-size: 10px !important;
        }
        #hotellogo {
            width: 100%;
            height: 350px;
            object-fit: fill;
        }
    }


    @media screen and (max-width:599px) {
        #sideBox {
            display: none;
        }
        #table {
            font-size: 10px !important;
        }
        #hotellogo {
            width: 100%;
            height: 250px;
            object-fit: fill;
        }

    }
    
</style>




